<!-- publishedJournalsDetails -->
<template>
  <div class="publishedJournalsDetails">
    <div class="details-content">
      <div class="left">
        <img
          :src="
            $route.query.coverId
              ? `${coverUrl}?pictureId=${$route.query.coverId}`
              : noImg
          "
          alt=""
        />
      </div>
      <div class="right">
        <div class="title">
          <div class="zh-title">{{ detailsData.name }}</div>
          <div class="en-title">{{ detailsData.enName }}</div>
        </div>
        <div class="info">
          <ul class="ul_1">
            <li>
              <p>曾用名：</p>
              <span>{{ detailsData.usedName || '--' }}</span>
            </li>
            <li>
              <p>主办单位：</p>
              <span>{{ detailsData.org || '--' }}</span>
            </li>
            <li>
              <p>出版周期：</p>
              <span>{{ detailsData.publishPeriod || '--' }}</span>
            </li>
            <li>
              <p>语种：</p>
              <span>{{ detailsData.language || '--' }}</span>
            </li>
            <li>
              <p>ISSN：</p>
              <span>{{ detailsData.issn || '--' }}</span>
            </li>
            <li>
              <p>CN：</p>
              <span>{{ detailsData.cn || '--' }}</span>
            </li>
          </ul>
          <ul class="ul_2">
            <li>
              <p>影响因子：</p>
              <span>
                知网：{{ detailsData.influence1 || '--' }}； 万方：{{
                  detailsData.influence2 || '--'
                }}； 百度：{{ detailsData.influence3 || '--' }}；
              </span>
            </li>
            <li>
              <p>出版地：</p>
              <span>{{ detailsData.publishRegion || '--' }}</span>
            </li>
            <li>
              <p>开本：</p>
              <span>{{ detailsData.bookSize || '--' }}</span>
            </li>
            <li>
              <p>邮发代号：</p>
              <span>{{ detailsData.postCode || '--' }}</span>
            </li>
            <li>
              <p>创刊时间：</p>
              <span>{{ detailsData.journalCreateTime || '--' }}</span>
            </li>
          </ul>
          <ul class="ul_3">
            <li><p>该刊被以下数据库收录：</p></li>
            <li
              v-for="(item, index) in detailsData.includedDatabases"
              :key="index"
            >
              <span>{{ item }}</span>
            </li>
            <li>
              <p>北京大学《中文核心期刊要目总揽》来源期刊：</p>
              <span>{{ detailsData.journalHonorSource || '--' }}</span>
            </li>
            <li>
              <p>期刊荣誉：</p>
              <span>{{ detailsData.journalHonor || '--' }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <commonTable
      :is-click="false"
      :table-data="tableData"
      :column-data="tableColumn"
      :init-obj="tablePageObj"
      @onClickTitle="handleOpenUrl"
      @onChangePageData="
        (pageObj) => {
          getTableData(pageObj)
        }
      "
    ></commonTable>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.contentAggregation
import commonTable from '@comp/commonTable'
export default {
  name: 'PublishedJournalsDetails',
  data() {
    return {
      noImg: require('@assets/img/noImg.jpg'),
      coverUrl: api.GetPictureStream.url,
      detailsData: '',
      tableData: [],
      tablePageObj: {
        page: 1,
        size: 10
      },
      tableColumn: [
        {
          title: '论文标题',
          prop: 'title',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '作者机构',
          prop: 'institutions',
          notSortable: true
        }
      ]
    }
  },
  props: {},
  components: { commonTable },
  computed: {},
  mounted() {
    this.getDetailsData()
    this.getTableData()
  },
  methods: {
    getDetailsData() {
      const params = {
        id: this.$route.query.magId
      }
      let url = api.GetIssuesDetails.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.detailsData = data
          this.detailsData.includedDatabases = JSON.parse(
            this.detailsData.includedDatabases
          )
        }
      })
    },
    getTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.$route.query.magId,
        year: this.$route.query.year,
        magNumber: this.$route.query.issue,
        page: page - 1,
        size
      }
      let url = api.GetIssueArticles.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.tablePageObj = {
            page,
            size,
            total: totalElements
          }
          this.tableData = content
        }
      })
    },
    // 跳转去官网
    handleOpenUrl(chooseItem) {
      const { htmlUrl = '', abstractUrl = '' } = chooseItem
      if (htmlUrl) {
        window.open(htmlUrl)
      } else if (abstractUrl) {
        window.open(abstractUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.publishedJournalsDetails {
  padding: 30px;
  .details-content {
    display: flex;
    margin-bottom: 30px;
    .left {
      img {
        width: 112px;
        height: 158px;
        object-fit: contain;
      }
    }
    .right {
      width: 100%;
      margin-left: 30px;
      .title {
        font-weight: bold;
        line-height: 17px;
        color: #4054af;
        .zh-title {
          font-size: 18px;
        }
        .en-title {
          font-size: 14px;
          margin-top: 6px;
        }
      }
    }
    .info {
      display: flex;
      margin-top: 20px;
      ul {
        width: 33%;
        li {
          line-height: 26px;
          p {
            display: inline-block;
            font-weight: bold;
            font-size: 14px;
            color: #666;
          }
        }
        span {
          font-size: 13px;
          color: #999;
        }
      }
      .ul_2 {
        width: 340px;
        margin: 0 20px;
      }
    }
  }
}
</style>
