import { render, staticRenderFns } from "./publishedJournalsDetails.vue?vue&type=template&id=56591c0b&scoped=true"
import script from "./publishedJournalsDetails.vue?vue&type=script&lang=js"
export * from "./publishedJournalsDetails.vue?vue&type=script&lang=js"
import style0 from "./publishedJournalsDetails.vue?vue&type=style&index=0&id=56591c0b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56591c0b",
  null
  
)

export default component.exports